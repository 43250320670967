<!-- ########## START: HEAD PANEL ########## -->
<div class="br-header">
  <div class="br-header-left">
    <div class="navicon-left hidden-md-down"><a id="btnLeftMenu" href=""><img src="assets/img/icons/menu.svg"
          class="icon-menudots"></a>
    </div>
    <div class="navicon-left hidden-lg-up"><a id="btnLeftMenuMobile" href=""><img src="assets/img/icons/menu.svg"
          class="icon-menudots"></a>
    </div>
  </div><!-- br-header-left -->
  <div class="br-header-right">
    <nav class="nav">
      <div class="dropdown">
        <a href="" class="nav-link nav-link-profile" data-toggle="dropdown">
          <p class="logged-name hidden-md-down"><span>Olá,</span> {{user.name}}</p>
          <img *ngIf="user.imagem_full == null" src="https://via.placeholder.com/500" class="wd-32 rounded-circle"
            alt="">
          <img *ngIf="user.imagem_full != null" src="{{user.imagem_full}}" class="wd-32 rounded-circle" alt="">
          <!-- <span class="square-10 bg-success"></span> -->
        </a>
        <div class="dropdown-menu dropdown-menu-header wd-250">
          <div class="tx-center">
            <a routerLink="/dashboard/account">
              <img *ngIf="user.imagem_full == null" src="https://via.placeholder.com/500" class="wd-80 rounded-circle"
                alt="">
              <img *ngIf="user.imagem_full != null" src="{{user.imagem_full}}" class="wd-80 rounded-circle" alt="">
            </a>
            <h6 class="logged-fullname">{{user.name}}</h6>
            <p>{{user.email}}</p>
          </div>
          <!--<hr>
           <div class="tx-center">
            <span class="profile-earning-label">Earnings After Taxes</span>
            <h3 class="profile-earning-amount">$13,230 <i class="icon ion-ios-arrow-thin-up tx-success"></i></h3>
            <span class="profile-earning-text">Based on cotainer price.</span>
          </div>
          <hr> -->
          <ul class="list-unstyled user-profile-nav">
          <!--  <li><a href="#" routerLink="/dashboard/account"><i class="icon ion-ios-person"></i> Meus dados</a></li>
            <li><a href="#" routerLink="/dashboard/settings"><i class="icon ion-ios-gear"></i> Configurações</a></li> 
            <li><a href="#" routerLink="/auth/logout"><i class="icon ion-power"></i> Sair</a></li>-->
          </ul>
        </div><!-- dropdown-menu -->
      </div><!-- dropdown -->
    </nav>
    <div class="navicon-right">
      <a class="pos-relative" id="btnRightMenu" href="" routerLink="/auth/logout" >
        <i class="fas fa-sign-out-alt tx-20"></i>
      </a>
    </div> 
    <!-- navicon-right -->
  </div><!-- br-header-right -->
</div><!-- br-header -->
<!-- ########## END: HEAD PANEL ########## -->
