import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { Http } from './http';

@Injectable({
  providedIn: 'root'
})
export class GlobalGuard implements CanActivate {
  url;
  constructor(private globalService: GlobalService, private http: Http, private router: Router) {
    this.url = environment.url;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem("token");
    /*  console.log("NextAutorization", next);
     console.log("Token", token); */
    if (token == null) {
      this.router.navigate(["/auth/login"]);
      return false;

    }
    return this.http.get(this.url + "/user/complete", { params: { url: state.url } })
      .toPromise()
      .then((result: any) => {
        if (result.status !== "ATIVO") {
          this.router.navigate(["/auth/logout"], {
            queryParams: {
              message: "Seu usuário está inativo"
            }
          });
          return false;
        }
        if (result.type === "CLIENTE") {
          this.router.navigate(["/auth/logout"], {
            queryParams: {
              message: "Seu usuário não tem permissão para acessar o administrador"
            }
          });
          return false;
        }
        this.globalService.user = result;
        return true;
      })
      .catch(result => {
        console.log("RESULT On fail", result);
        if (result.status === 403) {
          this.router.navigate(["/403"]);
        }
        else if (result.status === 401) {
          this.router.navigate(["/auth/login"]);
        }
        else {
          this.router.navigate(["/404"]);
        }

        return false;
      });
  }
}
