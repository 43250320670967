import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { NavigationEnd, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  roles: any = [];
  constructor(private globalService: GlobalService, private router: Router) {


  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.verificaEAtivaRota();
        }, 200);
      }
    });
    this.roles = this.globalService.user.roles;
    setTimeout(() => {
      $('.br-sideleft').on('click', '.br-menu-link', function () {
        var nextElem = $(this).next();
        var thisLink = $(this);

        if (nextElem.hasClass('br-menu-sub')) {

          if (nextElem.is(':visible')) {
            thisLink.removeClass('show-sub');
            nextElem.slideUp();
          } else {
            $('.br-menu-link').each(function () {
              $(this).removeClass('show-sub');
            });

            $('.br-menu-sub').each(function () {
              $(this).slideUp();
            });

            thisLink.addClass('show-sub');
            nextElem.slideDown();
          }
          return false;
        }
      });
      this.verificaEAtivaRota();
    }, 200);
  }

  verificaEAtivaRota() {
    let li = $(".br-menu-item").find(".br-menu-sub").find(".active").parent().parent().parent();
    if (li.length) {
      $(".br-menu-item").find("a.with-sub").removeClass("active");
      $(li.find("a")[0]).addClass("active");
    } else {
      $(".br-menu-item").find("a.with-sub").removeClass("active");
    }
  }

  hasRole(role) {
    let has = false;
    this.roles.forEach((value, index) => {
      if (value.id_role === role) {
        has = true;
        return;
      }
    });

    return has;
  }



}
