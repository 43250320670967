import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassicComponent } from './layout/classic/classic.component';
import { GlobalGuard } from './global.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: ClassicComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: "Dashboard" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'usuario',
        loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule),
        data: { title: "Gerenciamento de usuários" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'cliente',
        loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule),
        data: { title: "Gerenciamento de clientes" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'status',
        loadChildren: () => import('./status/status.module').then(m => m.StatusModule),
        data: { title: "Gerenciamento de Status" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'pedido',
        loadChildren: () => import('./pedido/pedido.module').then(m => m.PedidoModule),
        data: { title: "Gerenciamento de Pedidos" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'item',
        loadChildren: () => import('./item/item.module').then(m => m.ItemModule),
        data: { title: "Gerenciamento de Itens" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'conteiner',
        loadChildren: () => import('./conteiner/conteiner.module').then(m => m.ConteinerModule),
        data: { title: "Gerenciamento de Conteiners" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'rotas',
        loadChildren: () => import('./logistics/delivery-route/delivery-route.module').then(m => m.DeliveryRouteModule),
        data: { title: "Gerenciamento de Rotas" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'financeiro',
        loadChildren: () => import('./financeiro/financeiro.module').then(m => m.FinanceiroModule),
        data: { title: "Centro de custos" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'relatorio',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        data: { title: "Relatório Financeiro" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'furnitures',
        loadChildren: () => import('./furnitures/furniture.module').then(m => m.FurnitureModule),
        data: { title: "Gerenciamento de móveis" },
        canActivate: [GlobalGuard],
      },
      {
        path: 'address',
        loadChildren: () => import('./envio/envio.module').then(m => m.EnvioModule),
        data: { title: "Gerenciamento de endereços" },
        canActivate: [GlobalGuard],
      },
      /*  {
         path: 'dashboard',
         loadChildren: './dashboard/dashboard.module#DashboardModule',
         data: { title: "Dashboard" },
       }, */
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
