import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { ClassicComponent } from './classic/classic.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingComponent } from './loading/loading.component';
import { SharedMaterialModule } from '../shared-material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { EnvioModule } from '../envio/envio.module';

@NgModule({
  declarations: [MenuComponent, ClassicComponent, LoadingComponent, NavbarComponent],
  entryComponents: [MenuComponent, LoadingComponent, NavbarComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedMaterialModule,
    RouterModule
  ],
  exports: [
    MenuComponent,
    SharedMaterialModule,
  ]
})
export class LayoutModule { }
