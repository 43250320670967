<app-menu></app-menu>
<app-navbar></app-navbar>

<ng-container #chatContainer>

</ng-container>
<!-- <app-chat-window></app-chat-window> -->


<!--

<div class="col-lg-3 col-chat">
  <div class="box-chat">

    <div class="col-lg-12 header-chat">
      <div class="row">
        <div class="col-lg-2">
          <img src="../assets/img/medico/1.jpg" class="img-box-chat" alt="">
          <div class="contact-status-indicator bg-success ind-chat"></div>
        </div>
        <div class="col-lg-8">
          <p class="name-chat">Belinda Connor</p>
          <p class="esp-chat">Neurologista</p>
        </div>
        <div class="col-lg-2">
          <i class="fas fa-times close-chat"></i>
        </div>
      </div>
    </div>

    <div class="col-lg-12 content-chat">
      <p class="msgme"><span>Olá, td bem?</span></p>
      <p class="msgyou"><span>Tudo sim e contigo? Quanto tempo não nos falamos.</span></p>
      <p class="msgme"><span>É mesmo, depois que me mudei para SP não nos vimos.</span></p>
      <p class="msgyou"><span>Tudo sim e contigo? Quanto tempo não nos falamos.</span></p>
      <p class="msgme"><span>É mesmo, depois que me mudei para SP não nos vimos.</span></p>
      <p class="msgme"><span>Olá, td bem?</span></p>
    </div>

    <div class="col-lg-12 msg-chat">
      <div class="box-msg">
        <div class="row">
          <div class="col-lg-10 txt-area">
            <p class="txt-chat">Teste de envio de msg</p>
          </div>
          <div class="col-lg-2 icon-send">
            <i class="fas fa-paper-plane"></i>
          </div>
        </div>
      </div>

    </div>

  </div>
</div> -->

<!-- ########## START: RIGHT PANEL ########## -->
<div class="br-sideright">

  <!-- Tab panes -->
  <!-- <app-cotainer-chat></app-cotainer-chat> -->

</div><!-- br-sideright -->
<!-- ########## END: RIGHT PANEL ########## --->

<!-- ########## START: MAIN PANEL ########## -->
<div class="br-mainpanel" *ngIf="closedTemplate">
  <div class="br-pageheader" *ngIf="!loadingRouteConfig">
    <nav class="breadcrumb pd-0 mg-0 tx-12">
      <a class="breadcrumb-item" href="index.html">Inicio</a>
      <span class="breadcrumb-item active">{{title}}</span>
    </nav>
  </div>
  <div class="br-pagebody" *ngIf="!loadingRouteConfig">
    <router-outlet></router-outlet>
  </div><!-- br-pagebody -->
  <footer class="br-footer">
    <div class="footer-left">
      <div class="mg-b-2">Copyright © {{fullYear}}. {{companyName}}. </div>
      <div>Powered by <a target="_blank" href="//utu.agency"><b>UTU Agency</b></a></div>
    </div>
  </footer>
  <ng-container *ngIf="loadingRouteConfig">
    <app-loading></app-loading>
  </ng-container>
</div><!-- br-mainpanel -->
<!-- ########## END: MAIN PANEL ########## -->


<div *ngIf="!closedTemplate">
  <router-outlet></router-outlet>
</div>
<div id="loadingOverlay" *ngIf="globalService.loading">
  <div class="loading">
    <img src="assets/img/logo.png" class="logo img-responsive" /><br />
    <div class="spinner-border text-primary" role="status"> </div>
  </div>
</div>