import { Component, OnInit, AfterViewInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  user: any = {};
  constructor(private globalService: GlobalService) {
    this.user = this.globalService.user;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $('#btnLeftMenu').on('click', function () {
      var menuText = $('.menu-item-label');

      if ($('body').hasClass('collapsed-menu')) {
        $('body').removeClass('collapsed-menu');

        // show current sub menu when reverting back from collapsed menu
        $('.show-sub + .br-menu-sub').slideDown();

        $('.br-sideleft').one('transitionend', function (e) {
          menuText.removeClass('op-lg-0-force');
          menuText.removeClass('d-lg-none');
        });

      } else {
        $('body').addClass('collapsed-menu');

        // hide toggled sub menu
        $('.show-sub + .br-menu-sub').slideUp();

        menuText.addClass('op-lg-0-force');
        $('.br-sideleft').one('transitionend', function (e) {
          menuText.addClass('d-lg-none');
        });
      }
      return false;
    });

    $('#btnRightMenu').on('click', function () {
      $('body').addClass('show-right');
      return false;
    });
  }

}
